import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { version, baseUrl } from "../../../environments/environment";
import { AuthenticationService, SocialLogin } from "../../authentication.Service";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SurveyService } from '../../survey.Service';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ZendeskUtil } from '../../utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FeatureTourDialogComponent } from '../../feature-tour-dialog/feature-tour-dialog.component';
import { fadeInAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, fadeInLeftAnimation, fadeInRightAnimation, rubberBandAnimation } from 'angular-animations';

@Component({
  selector: 'app-newlogin',
  templateUrl: './newlogin.component.html',
  styleUrl: './newlogin.component.css',
  animations: [
    fadeInOnEnterAnimation({ duration: 1000 }),
    fadeOutOnLeaveAnimation({ duration: 100 }),
    fadeInAnimation({ anchor: 'enter', duration: 1000 }),
    fadeInAnimation({ anchor: 'errorFadein', duration: 1000, delay: 100 })
  ]
})
export class NewloginComponent implements OnInit, OnDestroy {
  private config: {
    version: string
  };

  zendeskCheck: string[] = [
    "https://",
    "https://localhost",
    "https://staging.",
    "https://pg.",
    "https://ire.",
    "https://sg.",
    "https://ldn.",
    "https://ams.",
    "https://lbg"
  ];

  start: boolean = false;
  fadeInLeftState = false;
  formGroup: FormGroup;
  p1: boolean = false;
  p2: boolean = false;
  p3: boolean = false;
  private isTrue: boolean = false;
  errorMsg: string = "";
  displayError = false;
  displayWait = false;
  hide = true;
  breakpoint: number = 0;
  GoogleLoginProvider = GoogleLoginProvider;
  subscription?: Subscription;
  dialogRef: MatDialogRef<any, any>;
  enterDetails: boolean = true;
  loggingIn: boolean = false;

  destroy = new Subject();

  destroy$ = this.destroy.asObservable();
  top: number = 0;

  currentVersion: string;
  currentYear = new Date().getFullYear();

  @ViewChild('loggingInDialog') loggingInDialog: TemplateRef<any>;

  constructor(private http: HttpClient,
    private actRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private snackBar: MatSnackBar,
    private socialService: SocialAuthService,
    private breakpointObserver: BreakpointObserver,
    private surveyService: SurveyService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {
    this.currentVersion = version;
    if (this.authenticationService.getAccessToken() != "") {
      try {
        this.socialService.signOut();
      }
      catch { }
    }

    this.authenticationService.logoutWithoutNavigate();
    this.formGroup = new FormGroup({
      email: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required]),
      deviceType: new FormControl(""),
      ipAddress: new FormControl("")
    });
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnInit(): void {
    this.config = require('../../../assets/files/config.json')

    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');

    this.http.get<{ version: string }>('../../assets/files/config.json', { headers })
      .subscribe(config => {
        if (config.version !== this.config.version) {
          let temp = this.snackBar.open("New version of the platform available", "Reload", {
            horizontalPosition: "center",
            verticalPosition: "top"
          });
          temp.afterDismissed().subscribe(() => {
            this.http
              .get("", { headers, responseType: "text" })
              .subscribe(() => location.reload());
          });
        }
      });

    this.subscription = this.socialService.authState.subscribe((user) => {
      this.authenticationService.getIpAddress().subscribe(
        {
          next: (result) => {
            this.formGroup.controls.ipAddress.setValue(result.ip);
            this.subSocialLogin(user, result.ip);
          },
          error: (error) => {
            this.subSocialLogin(user, "");
          }
        });
    });
  }

  subSocialLogin(user, ipAddress) {
    let model = new SocialLogin();
    if (user != null) {
      this.enterDetails = false;
      this.loggingIn = true;
      model.EmailAddress = user.email;
      model.FirstName = user.firstName;
      model.LastName = user.lastName;
      model.PictureUrl = user.photoUrl;
      model.Provider = user.provider;
      model.UserID = user.id;
      model.IpAddress = ipAddress;
      let deviceInfo = this.deviceService.getDeviceInfo();
      model.DeviceType = deviceInfo.device + ":" + deviceInfo.deviceType + ":" + deviceInfo.browser;
      this.displayWait = true;
      this.authenticationService.socialLogin(model).subscribe(result => {
        this.displayWait = false;
        this.checkToken((result as any).Token);
      },
        error => {
          this.loggingIn = false;
          this.enterDetails = true;
          this.openSnackbar("There was a problem using the credentials", "Cancel");
          this.displayWait = false;
        });
    }
    else {
      this.loggingIn = false;
      this.enterDetails = true;
      this.openSnackbar("There was a problem using the credentials", "Cancel");
      this.displayWait = false;
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.destroy.next(this.destroy$);
  }

  ngAfterViewInit(): void {
    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$)).subscribe((e: Event) => {
      this.top = this.getYPosition(e);
    });

    Promise.resolve(null).then(() => {
      this.start = true;
    });
  }

  logInWithFacebook(): boolean {
    this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID);
    return false;
  }

  refreshGoogleToken(): void {
    this.displayWait = true;
    this.socialService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
    this.displayWait = false;
  }

  wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }

  loginProcess() {
    this.enterDetails = false;
    this.loggingIn = true;
    this.formGroup.controls.email.setErrors(null);
    this.formGroup.controls.password.setErrors(null);
    if (this.formGroup.valid) {
      this.displayWait = true;
      let deviceInfo = this.deviceService.getDeviceInfo();
      this.formGroup.controls.deviceType.setValue(deviceInfo.device + ":" + deviceInfo.deviceType + ":" + deviceInfo.browser);
      this.authenticationService.getIpAddress().subscribe(
        {
          next: (result) => {
            this.formGroup.controls.ipAddress.setValue(result.ip);
            this.subLogin();
          },
          error: (error) => {
            this.subLogin();
          }
        });
    }

    return false;
  }

  subLogin() {
    this.authenticationService.loginNoCheck(this.formGroup.value).subscribe({
      next: (result) => {
        this.displayWait = false;
        if (result.Status == 0) {
          this.authenticationService.setAccessToken(result.Token);
          this.router.navigate(['../2fa']);
          return;
        }

        this.checkToken(result.Token);
      },
      error: (error) => {
        this.displayWait = false;
        this.loggingIn = false;
        this.enterDetails = true;
        this.displayError = true;
        this.formGroup.controls.email.setErrors({ invalid: '' });
        this.errorMsg = 'There is a problem with the credentials';
        this.formGroup.controls.password.setErrors({ invalid: this.errorMsg });
      }
    });
  }

  getError(): string {
    if (!this.displayError) {
      return '';
    }

    this.formGroup.controls.email.setErrors({ invalid: '' });
    this.errorMsg = 'There is a problem with the credentials';
    this.formGroup.controls.password.setErrors({ invalid: this.errorMsg });
    return this.errorMsg;
  }

  private checkToken(token: any) {
    this.authenticationService.setAccessToken(token);
    let page = '';
    if (this.authenticationService.isAuthorized(['Admin'])) {
      page = '/system-dashboard';
      this.signIntoZenDesk(page);
    }
    else if (this.authenticationService.isAuthorized(['developer'])) {
      page = '/smshome/keys';
      this.signIntoZenDesk(page);
    }
    else {
      this.surveyService.getSurveyCount()
        .subscribe(response => {
          if (response > 0) {
            page = '/surveydashboard';
          } else {
            page = '/startfromtemplate';
          }

          if (this.authenticationService.loadSetting('homeTourDone') != "") {
            const dialogRef = this.dialog.open(FeatureTourDialogComponent, { disableClose: true });
          }
          this.signIntoZenDesk(page);
        },
          error => {
            if (error.status === 401) {
              this.router.navigate(['../']);
            }
          });
    }
  }

  private signIntoZenDesk(redirectTo: string) {
    let rt = window.location.href.toString();
    for (let i = 0; i < this.zendeskCheck.length; i++) {
      if (rt.startsWith(this.zendeskCheck[i])) {
        this.router.navigate(['..' + redirectTo]);
        return;
      }
    }

    this.authenticationService.zendeskCheck().subscribe(
      result => {
        ZendeskUtil.signin(result.Value, redirectTo);
      },
      error => {
        console.error("There was a problem with the login for Zendesk:" + error)
      });
  }

  checkStyle(): string {
    if (this.top > 5) {
      return 'mat-elevation-z2';
    }

    return '';
  }

  openTerms() {
    const url = this.router.createUrlTree(['/termsofservice'])
    window.open(url.toString(), '_blank')
  }

  openPrivacy() {
    const url = this.router.createUrlTree(['/privacystatement'])
    window.open(url.toString(), '_blank')
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  getYPosition(e): number {
    return e.target.scrollingElement.scrollTop;
  }

  startForFree() {
    this.start = !this.start;
  }

  onAppear() {
    let a = 0;
  }

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }
}
