<form [formGroup]="firstFormGroup">
  <br />
  <h3>Two-factor authentication</h3>
  <p>
    Two-factor authentication (2FA) is an extra layer of security used when logging into our services.<br />
    <a href="">Learn more</a>
  </p>
  <div>
    <div *ngIf="smsEnabled">

    </div>
  </div>
  <p *ngIf="!emailSetup">
    Use your email account to get two-factor authentication codes when prompted.
  </p>
  <div class="mb-10">
    {{user.EmailAddress}}&nbsp;
    <button *ngIf="!hasSent && !emailSetup" mat-raised-button type="button" color="primary" (click)="send2FAEmail($event)">Send Email Verification Code</button>
  </div>
  <div *ngIf="hasSent && !emailSetup" class="mb-10">
    <mat-form-field appearance="outline" class="widthThirty">
      <mat-label>Confirm Access Code</mat-label>
      <input matInput type="text" placeholder="Confirm Access Code" formControlName="AccessCode" />
    </mat-form-field>&nbsp;
    <button mat-raised-button color="primary"  type="button" (click)="confirm2FAEmail()">Confirm Email Verification Code</button>&nbsp;
    <button mat-raised-button color="accent"  type="button" (click)="send2FAEmail($event)">Resend Email Verification Code</button>
  </div>
  <div class="mb-10" *ngIf="emailSetup">
    Email two-factor enaabled.&nbsp;
    <button mat-stroked-button color="primary"  type="button" (click)="disableEmail2FA()">Disable Email 2FA</button>&nbsp;
  </div>
  <mat-divider></mat-divider>
  <p>
    <br/>
    For additional security DIY Surveys can send you an SMS with a two-factor authentication code when prompted. SMS cannot be delivered in all countries.
    Check that <a href="">your country is supported</a> before you select this option.
  </p>
  <div>
    <mat-form-field appearance="outline" class="widthThirty">
      <mat-label>Mobile Number</mat-label>
      <input matInput type="text" [readonly]="smsSetup" placeholder="Mobile Number" formControlName="MobileNumber" />
    </mat-form-field>&nbsp;
    <div class="mb-10" *ngIf="smsSetup">
      SMS two-factor enaabled.&nbsp;
      <button mat-stroked-button color="primary"  type="button" (click)="disableSms2FA()">Disable SMS 2FA</button>&nbsp;
    </div>
    <button *ngIf="!smsSetup && !hasSmsSent" mat-raised-button  type="button" color="primary" (click)="send2FASms()">Send SMS Verification Code</button>&nbsp;
    <div *ngIf="hasSmsSent && !smsSetup">
      <mat-form-field appearance="outline" class="widthThirty">
        <mat-label>Confirm SMS Access Code</mat-label>
        <input matInput type="text" placeholder="Confirm SMS Access Code" formControlName="SmsAccessCode" />
      </mat-form-field>&nbsp;
      <button mat-raised-button color="primary"  type="button" (click)="confirm2FASms()">Confirm SMS Verification Code</button>&nbsp;
      <button mat-raised-button color="accent"  type="button" (click)="send2FASms()">Resend SMS Verification Code</button>&nbsp;
    </div>
  </div>
  <div *ngIf="devices.length > 0">
    <mat-divider></mat-divider>
    <div class="mt-15">
      <h3>Recent Login History</h3>
    </div>
    <div class="fieldRowWithPadding" *ngFor="let device of devices; let i = index">
      <div>IPAddress: {{device.IPAddress}}&nbsp;</div>
      <div>, Country: {{device.Country}}&nbsp;</div>
      <div>, Device: {{device.DeviceType}}&nbsp;</div>
      <div>, Last Logged In: {{device.LastUsed  | date:'medium'}}</div>
    </div>
  </div>
</form>
