import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserGroup, Group, Policy, PolicySettings, AuthenticationService } from "../../authentication.Service";
import { FormControl, FormGroup } from '@angular/forms';
import { plainToClass } from 'class-transformer';
import { JsonCyclic } from '../../utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-policy-detail',
  templateUrl: './policy-detail.component.html',
  styleUrl: './policy-detail.component.css'
})
export class PolicyDetailComponent implements OnInit {
  formGroup: FormGroup = null;
  settings: PolicySettings = new PolicySettings();
  defaultSettings: PolicySettings = new PolicySettings();

  constructor(public translate: TranslateService,
    private authService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      Name: new FormControl(),
      Description: new FormControl(),
      PolicyType: new FormControl(),
      Switch: new FormControl(),
      From: new FormControl(),
      To: new FormControl(),
      Number: new FormControl(),
      Create: new FormControl(),
      View: new FormControl(),
      Edit: new FormControl(),
      Delete: new FormControl(),
      Value: new FormControl()
    });
    this.settingsToForm();
  }

  settingsToForm() {
    if (this.formGroup == null || this._policy == null) {
      return;
    }

    this.formGroup.controls.Name.setValue(this._policy.Name);
    this.formGroup.controls.Description.setValue(this._policy.Description);
    this.formGroup.controls.PolicyType.setValue(this._policy.TypeId);
    this.formGroup.controls.Switch.setValue(this.settings.Switch);
    this.formGroup.controls.From.setValue(this.settings.From);
    this.formGroup.controls.To.setValue(this.settings.To);
    this.formGroup.controls.Number.setValue(this.settings.Number);
    this.formGroup.controls.Create.setValue(this.settings.Create);
    this.formGroup.controls.View.setValue(this.settings.View);
    this.formGroup.controls.Edit.setValue(this.settings.Edit);
    this.formGroup.controls.Delete.setValue(this.settings.Delete);
    this.formGroup.controls.Value.setValue(this.settings.Value);
  }

  save() {
    this._policy.Name = this.formGroup.controls.Name.value;
    this._policy.Description = this.formGroup.controls.Description.value;
    this._policy.TypeId = this.formGroup.controls.PolicyType.value;
    this.settings = new PolicySettings();
    switch (this._policy.TypeId) {
      case 1:
      this.settings.Switch = this.formGroup.controls.Switch.value;
        break;
      case 2:
        this.settings.From = this.formGroup.controls.From.value;
        this.settings.To = this.formGroup.controls.To.value;
        this.settings.Number = this.formGroup.controls.Number.value;
        break;
      case 3:
        this.settings.Create = this.formGroup.controls.Create.value;
        this.settings.View = this.formGroup.controls.View.value;
        this.settings.Edit = this.formGroup.controls.Edit.value;
        this.settings.Delete = this.formGroup.controls.Delete.value;
        break;
      case 4:
        this.settings.Value = this.formGroup.controls.Value.value;
        break;
    }

    this._policy.Settings = JsonCyclic.toJson(this.settings);
    this.authService.savePolicy(this._policy).subscribe(
      result => {
        this._policy = result;
        this.settingsToForm();
        this._preview = true;
      }
    )
  }

  cancel() {
    this._preview = true;
  }

  clickOnButton(action: number) {
    switch (action) {
      case 0:
        this.preview = false;
        break;
      case 1:
        this.onClick?.emit({ action: 'delete', id: this._policy.PolicyId });
    }
  }

  @Input()
  get preview(): boolean {
    return this._preview;
  }
  set preview(value: boolean) {
    this._preview = value;
  }
  private _preview: boolean = false;

  @Input()
  get userGroup(): UserGroup {
    return this._userGroup;
  }
  set userGroup(value: UserGroup) {
    this._userGroup = value;
  }
  private _userGroup: UserGroup = null;

  @Input()
  get defaultPolicy(): Policy {
    return this._defaultPolicy;
  }
  set defaultPolicy(value: Policy) {
    this._defaultPolicy = value;
    if (this._defaultPolicy?.Settings != "") {
      try {
        this.defaultSettings = plainToClass(PolicySettings, JSON.parse(this._defaultPolicy.Settings));
      }
      catch {
        this.defaultSettings = new PolicySettings();
      }
    }
  }
  private _defaultPolicy: Policy = null;

  @Input()
  get policy(): Policy {
    return this._policy;
  }
  set policy(value: Policy) {
    this._policy = value;
    this.settings = new PolicySettings();
    if (this._policy?.Settings != "") {
      try {
        this.settings = plainToClass(PolicySettings, JSON.parse(this._policy.Settings));
      }
      catch {
        this.settings = new PolicySettings();
      }
    }

    this.settingsToForm();
  }
  private _policy: Policy = null;

  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();
}
