<div *ngIf="preview && policy">
  <div class="column-flex-flow margin-5">
      <div class="row-flex-flow">
        <div><strong>{{policy.Name}}</strong></div>&nbsp;
        <div>{{policy.Description}}</div>
      </div>
      <div class="row-flex-flow">
        <div class="proc-def-status-div">
          <div><span class="sub-title">({{policy.PolicyId}})&nbsp;</span>Modified: {{policy.Amended | date:'shortDate': '' : translate.currentLang}} {{policy.Amended | date:'mediumTime': '' : translate.currentLang}}</div>
        </div>
        <div class="row-flex-flow" style="align-content:end;margin-top:-8px">
          <div class="proc-def-actionBtn-div">
            <button mat-mini-fab color="accent" matTooltip="Edit Group Policy" (click)="clickOnButton(0)"><mat-icon>edit</mat-icon></button>
          </div>
          <div *ngIf="userGroup == null" class="proc-def-actionBtn-div">
            <button mat-mini-fab color="accent" matTooltip="Delete Group Policy" (click)="clickOnButton(1)"><mat-icon>delete</mat-icon></button>
          </div>
        </div>
    </div>
  </div>
</div>
<div *ngIf="!preview">
  <ng-container *ngTemplateOutlet="editorButtons"></ng-container>
  <div [formGroup]="formGroup" class="row-flex-flow">
    <div class="column-flex-flow margin-5">
      <h3>Settings</h3>
      <div>
        <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="PolicyType">
          <mat-button-toggle [disabled]="userGroup" [value]="1" [checked]="policy.TypeId == 1">On/Off</mat-button-toggle>
          <mat-button-toggle [disabled]="userGroup" [value]="2" [checked]="policy.TypeId == 2">Number</mat-button-toggle>
          <mat-button-toggle [disabled]="userGroup" [value]="3" [checked]="policy.TypeId == 3">Create/View/Edit/Delete</mat-button-toggle>
          <mat-button-toggle [disabled]="userGroup" [value]="4" [checked]="policy.TypeId == 4">String</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div>
        &nbsp;
      </div>
      <div *ngIf="formGroup.controls?.PolicyType?.value == 1">
        <h3>On/Off</h3>
        A flag or switch that can been turned on or off.
        <div>
          <mat-slide-toggle formControlName="Switch">Default Value: {{formGroup.controls.Switch.value ? 'On' : 'Off'}}</mat-slide-toggle>
        </div>
      </div>
      <div *ngIf="formGroup.controls?.PolicyType?.value == 2">
        <h3>Number</h3>
        A value that can range between two values with a default value.
        <div>
          <mat-form-field appearance="outline" class="user-details-invite-role-div">
            <mat-label>From</mat-label>
            <input matInput placeholder="From" formControlName="From">
          </mat-form-field>&nbsp;-&nbsp;
          <mat-form-field appearance="outline" class="user-details-invite-role-div">
            <mat-label>To</mat-label>
            <input matInput placeholder="To" formControlName="To">
          </mat-form-field>&nbsp;
          <mat-form-field appearance="outline" class="user-details-invite-role-div">
            <mat-label>Default</mat-label>
            <input matInput placeholder="Default" formControlName="Number">
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="formGroup.controls?.PolicyType?.value == 3">
        <h3>Create/View/Edit/Delete</h3>
        A set of flags indicating what sort of access is available to the object defined by the policy.
        <div>
          <div *ngIf="userGroup == null || userGroup && defaultSettings?.Create">
            <mat-checkbox class="example-margin" formControlName="Create"><strong>Create</strong><br />Create, add, or generate</mat-checkbox>
          </div>
          <div *ngIf="userGroup == null || userGroup && defaultSettings?.View">
            <mat-checkbox class="example-margin" formControlName="View"><strong>View</strong><br />View but not edit and/or save anything</mat-checkbox>
          </div>
          <div *ngIf="userGroup == null || userGroup && defaultSettings?.Edit">
            <mat-checkbox class="example-margin" formControlName="Edit"><strong>Edit</strong><br />Edit the object defined by the policy</mat-checkbox>
          </div>
          <div *ngIf="userGroup == null || userGroup && defaultSettings?.Delete">
            <mat-checkbox class="example-margin" formControlName="Delete"><strong>Delete</strong><br />Delete, remove, or hide the object defined by the policy</mat-checkbox>
          </div>
        </div>
      </div>
      <div *ngIf="formGroup.controls?.PolicyType?.value == 4">
        <h3>String</h3>
        A value that can be any string (e.g. associated with a regex pattern).
        <div>
          <mat-form-field appearance="outline" style="width:100%">
            <mat-label>Value</mat-label>
            <input matInput placeholder="Value" formControlName="Value">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="margin-5" style="width:100%">
      <h3>Policy Basic Details</h3>
      <div class="wrapFlex">
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Name" [readonly]="userGroup != null" formControlName="Name">
        </mat-form-field>&nbsp;&nbsp;
      </div>
      <div>
        <mat-form-field appearance="outline" class="fullWidth">
          <mat-label>Description</mat-label>
          <textarea matInput placeholder="Description" [readonly]="userGroup != null" formControlName="Description"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="editorButtons"></ng-container>
</div>
<ng-template #editorButtons>
  <div class="simple-row">
    <div class="comm-edtr-divider-div">
      <mat-divider>
      </mat-divider>
    </div>
    <div>
      <button mat-mini-fab matTooltip="Save" (click)="save()"><mat-icon>save</mat-icon></button>
    </div>
    <div>
      <button mat-mini-fab matTooltip="Close" (click)="cancel()"><mat-icon>close</mat-icon></button>
    </div>
    <div class="comm-edtr-divider-div">
      <mat-divider>
      </mat-divider>
    </div>
  </div>
</ng-template>

