import { Component, Input, OnInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { CountryComboComponent } from '../../country-combo/country-combo.component';
import { COMMA, ENTER, L } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-firewall-settings',
  templateUrl: './firewall-settings.component.html',
  styleUrl: './firewall-settings.component.css'
})
export class FirewallSettingsComponent {
  formGroup: FormGroup;
  countryCtrl = new FormControl();
  filteredCountries: Observable<any[]>;
  countries: any[] = CountryComboComponent.locations;
  selectedCountries: any[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('countryInput') countryInput: ElementRef<HTMLInputElement> | undefined;

  constructor() {
    this.buildForm();
    this.filteredCountries = this.countryCtrl.valueChanges.pipe(
      startWith(null),
      map((country: any | null) => (country ? this._filter(country) : this.countries.slice())),
    );

  }

  buildForm() {
    this.formGroup = new FormGroup({
      countrySelectionType: new FormControl("bl"),
      countries: this.countryCtrl,
      ipAddressSelectionType: new FormControl("bl"),
      ipAddresses: new FormControl()
    })
  }

  get blCountry(): boolean {
    return this.formGroup?.controls.countrySelectionType.value == "bl" ?? false;
  }

  get blIpAddress(): boolean {
    return this.formGroup?.controls.ipAddressSelectionType.value == "bl" ?? false;
  }

  update() {

  }

  addCountry(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].value == value) {
          this.selectedCountries.push(this.countries[i]);
          break;
        }
      }
    }

    // Clear the input value
    event.chipInput!.clear();

    this.countryCtrl.setValue(null);
  }

  removeCountry(country: string): void {
    for (let i = 0; i < this.selectedCountries.length; i++) {
      if (this.selectedCountries[i].value == country) {
        this.selectedCountries.splice(i, 1);
        return;
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    for (let i = 0; i < this.countries.length; i++) {
      if (this.countries[i].value + " (" + this.countries[i].countryCode + ")" == event.option.viewValue) {
        this.selectedCountries.push(this.countries[i]);
        break;
      }
    }

    if (this.countryInput != undefined) {
      this.countryInput.nativeElement.value = '';
    }

    this.countryCtrl.setValue(null);
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter(country => country.value.toLowerCase().startsWith(filterValue));
  }
}
