import { Component } from '@angular/core';
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { UserService, UserSummary } from '../../user.Service';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { CountriesData } from 'countries-map';
import { AuthenticationService, DeviceListCountrySummary } from '../../authentication.Service';

@Component({
  selector: 'app-active-users',
  templateUrl: './active-users.component.html',
  styleUrls: ['./active-users.component.css']
})
export class ActiveUsersComponent {
  tempSummary: Observable<any>;
  summary: UserSummary[] = null;
  displayedColumns = ['UserID', 'BusinessType', 'LastLoggedIn', 'UserName'];
  dataSource = new MatTableDataSource(this.summary);
  mapData: CountriesData;
  countries: DeviceListCountrySummary[] = [];

  constructor(public translate: TranslateService,
    private userService: UserService,
    private authService: AuthenticationService) {
    this.tempSummary = userService.getTopUsers(5).pipe(tap<UserSummary[]>(result => {
      this.summary = result;
      this.dataSource = new MatTableDataSource(this.summary);
    }));
    this.authService.getLoginActivity().subscribe(
      result => {
        this.countries = result;
        this.mapData = {};
        for (let i = 0; i < this.countries.length; i++) {
          if (this.countries[i].Code.length > 2) {
            continue;
          }

          let country = this.countries[i];
          this.mapData[country.Code] = { value: country.Devices };
        }
      });
  }

  edit(row: any) {

  }

  countrySettings() {

  }

  businessType(user: UserSummary): string {
    if (UserService.hasRole(user.Roles, "Cash")) {
      return "monetization_on";
    }

    return "credit_card";
  }

  isTemplate(user: UserSummary): boolean {
    return UserService.hasRole(user.Roles, "template") || UserService.hasRole(user.Roles, "Admin");
  }

  templateType(user: UserSummary): string {
    return UserService.hasRole(user.Roles, "Admin") ? "A" : "T";
  }

}
