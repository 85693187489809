import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-flag',
  template: `<div
    *ngIf="this.country"
    [style]="this.style"
    [ngClass]="['ngx-flag', this.class]"
  ></div>`,
  styles: [
    `
      .ngx-flag {
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    `,
  ],
})
export class FlagComponent {
  @Input() country!: string;
  @Input() format!: string;
  @Input() size: any = 48;
  @Input() class: string = '';

  public imageUrl!: string;
  public style!: any;
  public database: any = db;
  public countryCode: string = '';

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.setImage();
  }

  setImage(): void {
    this.imageUrl = `assets/flags/${this.getFlag(this.getCode())}.svg`;
    this.style = {
      borderRadius: this.getFormat() == FORMAT.ROUND ? '9999px' : '0%',
      width: `${this.getSize()}px`,
      height:
        this.getFormat() == FORMAT.NONE
          ? `${Math.floor(this.getSize() / 1.5)}px`
          : `${this.getSize()}px`,
      backgroundImage: `url("${this.imageUrl}")`,
    };
  }

  getSize(): number {
    return isNaN(+this.size) ? +SIZE[this.size.toUpperCase()] : this.size;
  }

  getFormat(): string {
    return this.format ? this.format.toLowerCase() : FORMAT.NONE;
  }

  getCode(): string {
    return this.country?.toLowerCase() ?? "";
  }

  getFlag(code: string): string {
    return this.database[code.toLowerCase()];
  }
}

enum FORMAT {
  NONE = 'none',
  ROUND = 'round',
  SQUARE = 'square',
}

enum SIZE {
  XXS = 8,
  XS = 16,
  S = 24,
  M = 32,
  L = 48,
  XL = 64,
  XXL = 96,
}

export const db = {
  ad: "ad",
  and: "ad",
  andorra: "ad",
  ae: "ae",
  are: "ae",
  "united-arab-emirates-(the)": "ae",
  af: "af",
  afg: "af",
  afghanistan: "af",
  ag: "ag",
  atg: "ag",
  "antigua-and-barbuda": "ag",
  al: "al",
  alb: "al",
  albania: "al",
  am: "am",
  arm: "am",
  armenia: "am",
  ao: "ao",
  ago: "ao",
  angola: "ao",
  ar: "ar",
  arg: "ar",
  argentina: "ar",
  at: "at",
  aut: "at",
  austria: "at",
  au: "au",
  aus: "au",
  australia: "au",
  az: "az",
  aze: "az",
  azerbaijan: "az",
  ba: "ba",
  bih: "ba",
  "bosnia-and-herzegovina": "ba",
  bb: "bb",
  brb: "bb",
  barbados: "bb",
  bd: "bd",
  bgd: "bd",
  bangladesh: "bd",
  be: "be",
  bel: "be",
  belgium: "be",
  bf: "bf",
  bfa: "bf",
  "burkina-faso": "bf",
  bg: "bg",
  bgr: "bg",
  bulgaria: "bg",
  bh: "bh",
  bhr: "bh",
  bahrain: "bh",
  bi: "bi",
  bdi: "bi",
  burundi: "bi",
  bj: "bj",
  ben: "bj",
  benin: "bj",
  bn: "bn",
  brn: "bn",
  "brunei-darussalam": "bn",
  bo: "bo",
  bol: "bo",
  bolivia: "bo",
  br: "br",
  bra: "br",
  brazil: "br",
  bs: "bs",
  bhs: "bs",
  bahamas: "bs",
  bt: "bt",
  btn: "bt",
  bhutan: "bt",
  bw: "bw",
  bwa: "bw",
  botswana: "bw",
  by: "by",
  blr: "by",
  belarus: "by",
  bz: "bz",
  blz: "bz",
  belize: "bz",
  ca: "ca",
  can: "ca",
  canada: "ca",
  cd: "cd",
  cod: "cd",
  "the-democratic-republic-of-the-congo": "cd",
  cf: "cf",
  caf: "cf",
  "central-african-republic": "cf",
  cg: "cg",
  cog: "cg",
  congo: "cg",
  ch: "ch",
  che: "ch",
  switzerland: "ch",
  ci: "ci",
  civ: "ci",
  "cote-d-ivoire": "ci",
  cl: "cl",
  chl: "cl",
  chile: "cl",
  cm: "cm",
  cmr: "cm",
  cameroon: "cm",
  cn: "cn",
  chn: "cn",
  china: "cn",
  co: "co",
  col: "co",
  colombia: "co",
  cr: "cr",
  cri: "cr",
  "costa-rica": "cr",
  cu: "cu",
  cub: "cu",
  cuba: "cu",
  cv: "cv",
  cpv: "cv",
  "cabo-verde [f]": "cv",
  cy: "cy",
  cyp: "cy",
  cyprus: "cy",
  cz: "cz",
  cze: "cz",
  czechia: "cz",
  de: "de",
  deu: "de",
  germany: "de",
  dj: "dj",
  dji: "dj",
  djibouti: "dj",
  dk: "dk",
  dnk: "dk",
  denmark: "dk",
  dm: "dm",
  dma: "dm",
  dominica: "dm",
  do: "do",
  dom: "do",
  "dominican-republic": "do",
  dz: "dz",
  dza: "dz",
  algeria: "dz",
  ec: "ec",
  "ec-w": "ec-w",
  ecu: "ec",
  ecuador: "ec",
  ee: "ee",
  est: "ee",
  estonia: "ee",
  eg: "eg",
  egy: "eg",
  egypt: "eg",
  er: "er",
  eri: "er",
  eritrea: "er",
  es: "es",
  esp: "es",
  spain: "es",
  et: "et",
  eth: "et",
  ethiopia: "et",
  fi: "fi",
  fin: "fi",
  finland: "fi",
  fj: "fj",
  fji: "fj",
  fiji: "fj",
  fm: "fm",
  fsm: "fm",
  micronesia: "fm",
  fr: "fr",
  fra: "fr",
  france: "fr",
  ga: "ga",
  gab: "ga",
  gabon: "ga",
  gb: "gb",
  gbr: "gb",
  "united-kingdom": "gb",
  uk: "gb",
  "great-britain": "gb",
  gd: "gd",
  grd: "gd",
  grenada: "gd",
  ge: "ge",
  geo: "ge",
  georgia: "ge",
  gh: "gh",
  gha: "gh",
  ghana: "gh",
  gm: "gm",
  gmb: "gm",
  gambia: "gm",
  gn: "gn",
  gin: "gn",
  guinea: "gn",
  gq: "gq",
  gnq: "gq",
  "equatorial-guinea": "gq",
  gr: "gr",
  grc: "gr",
  greece: "gr",
  gt: "gt",
  gtm: "gt",
  guatemala: "gt",
  gw: "gw",
  gnb: "gw",
  "guinea-bissau": "gw",
  gy: "gy",
  guy: "gy",
  guyana: "gy",
  hn: "hn",
  hnd: "hn",
  honduras: "hn",
  hr: "hr",
  hrv: "hr",
  croatia: "hr",
  ht: "ht",
  hti: "ht",
  haiti: "ht",
  hu: "hu",
  hun: "hu",
  hungary: "hu",
  id: "id",
  idn: "id",
  indonesia: "id",
  ie: "ie",
  irl: "ie",
  ireland: "ie",
  il: "il",
  isr: "il",
  israel: "il",
  in: "in",
  ind: "in",
  india: "in",
  iq: "iq",
  irq: "iq",
  iraq: "iq",
  ir: "ir",
  irn: "ir",
  iran: "ir",
  is: "is",
  isl: "is",
  iceland: "is",
  it: "it",
  ita: "it",
  italy: "it",
  jm: "jm",
  jam: "jm",
  jamaica: "jm",
  jo: "jo",
  jor: "jo",
  jordan: "jo",
  jp: "jp",
  jpn: "jp",
  japan: "jp",
  ke: "ke",
  ken: "ke",
  kenya: "ke",
  kg: "kg",
  kgz: "kg",
  kyrgyzstan: "kg",
  kh: "kh",
  khm: "kh",
  cambodia: "kh",
  ki: "ki",
  kir: "ki",
  kiribati: "ki",
  km: "km",
  com: "km",
  comoros: "km",
  kn: "kn",
  kna: "kn",
  "saint-kitts-and-nevis": "kn",
  kp: "kp",
  prk: "kp",
  "north-korea": "kp",
  kr: "kr",
  kor: "kr",
  "south-korea": "kr",
  kw: "kw",
  kwt: "kw",
  kuwait: "kw",
  kz: "kz",
  kaz: "kz",
  kazakhstan: "kz",
  la: "la",
  lao: "la",
  laos: "la",
  lb: "lb",
  lbn: "lb",
  lebanon: "lb",
  lc: "lc",
  lca: "lc",
  "saint-lucia": "lc",
  li: "li",
  lie: "li",
  liechtenstein: "li",
  lk: "lk",
  lka: "lk",
  "sri-lanka": "lk",
  lr: "lr",
  lbr: "lr",
  liberia: "lr",
  ls: "ls",
  lso: "ls",
  lesotho: "ls",
  lt: "lt",
  ltu: "lt",
  lithuania: "lt",
  lu: "lu",
  lux: "lu",
  luxembourg: "lu",
  lv: "lv",
  lva: "lv",
  latvia: "lv",
  ly: "ly",
  lby: "ly",
  libya: "ly",
  ma: "ma",
  mar: "ma",
  morocco: "ma",
  mc: "mc",
  mco: "mc",
  monaco: "mc",
  md: "md",
  mda: "md",
  moldova: "md",
  me: "me",
  mne: "me",
  montenegro: "me",
  mg: "mg",
  mdg: "mg",
  madagascar: "mg",
  mh: "mh",
  mhl: "mh",
  "marshall-islands": "mh",
  mk: "mk",
  mkd: "mk",
  "north-macedonia": "mk",
  ml: "ml",
  mli: "ml",
  mali: "ml",
  mm: "mm",
  mmr: "mm",
  myanmar: "mm",
  mn: "mn",
  mng: "mn",
  mongolia: "mn",
  mr: "mr",
  mrt: "mr",
  mauritania: "mr",
  mt: "mt",
  mlt: "mt",
  malta: "mt",
  mu: "mu",
  mus: "mu",
  mauritius: "mu",
  mv: "mv",
  mdv: "mv",
  maldives: "mv",
  mw: "mw",
  mwi: "mw",
  malawi: "mw",
  mx: "mx",
  mex: "mx",
  mexico: "mx",
  my: "my",
  mys: "my",
  malaysia: "my",
  mz: "mz",
  moz: "mz",
  mozambique: "mz",
  na: "na",
  nam: "na",
  namibia: "na",
  ne: "ne",
  ner: "ne",
  niger: "ne",
  ng: "ng",
  nga: "ng",
  nigeria: "ng",
  ni: "ni",
  nic: "ni",
  nicaragua: "ni",
  nl: "nl",
  nld: "nl",
  netherlands: "nl",
  no: "no",
  nor: "no",
  norway: "no",
  np: "np",
  npl: "np",
  nepal: "np",
  nr: "nr",
  nru: "nr",
  nauru: "nr",
  nz: "nz",
  nzl: "nz",
  "new-zealand": "nz",
  om: "om",
  omn: "om",
  oman: "om",
  pa: "pa",
  pan: "pa",
  panama: "pa",
  pe: "pe",
  per: "pe",
  peru: "pe",
  pg: "pg",
  png: "pg",
  "papua-new-guinea": "pg",
  ph: "ph",
  phl: "ph",
  philippines: "ph",
  pk: "pk",
  pak: "pk",
  pakistan: "pk",
  pl: "pl",
  pol: "pl",
  poland: "pl",
  pt: "pt",
  prt: "pt",
  portugal: "pt",
  pw: "pw",
  plw: "pw",
  palau: "pw",
  py: "py",
  pry: "py",
  paraguay: "py",
  qa: "qa",
  qat: "qa",
  qatar: "qa",
  ro: "ro",
  rou: "ro",
  romania: "ro",
  rs: "rs",
  srb: "rs",
  serbia: "rs",
  ru: "ru",
  rus: "ru",
  russia: "ru",
  rw: "rw",
  rwa: "rw",
  rwanda: "rw",
  sa: "sa",
  sau: "sa",
  "saudi-arabia": "sa",
  sb: "sb",
  slb: "sb",
  "solomon-islands": "sb",
  sc: "sc",
  syc: "sc",
  seychelles: "sc",
  sd: "sd",
  sdn: "sd",
  sudan: "sd",
  se: "se",
  swe: "se",
  sweden: "se",
  sg: "sg",
  sgp: "sg",
  singapore: "sg",
  si: "si",
  svn: "si",
  slovenia: "si",
  sk: "sk",
  svk: "sk",
  slovakia: "sk",
  sl: "sl",
  sle: "sl",
  "sierra-leone": "sl",
  sm: "sm",
  smr: "sm",
  "san-marino": "sm",
  sn: "sn",
  sen: "sn",
  senegal: "sn",
  so: "so",
  som: "so",
  somalia: "so",
  sr: "sr",
  sur: "sr",
  suriname: "sr",
  ss: "ss",
  ssd: "ss",
  "south-sudan": "ss",
  st: "st",
  stp: "st",
  "sao-tome-and-principe": "st",
  sv: "sv",
  slv: "sv",
  "el-salvador": "sv",
  sy: "sy",
  syr: "sy",
  syria: "sy",
  sz: "sz",
  swz: "sz",
  eswatini: "sz",
  td: "td",
  tcd: "td",
  chad: "td",
  tg: "tg",
  tgo: "tg",
  togo: "tg",
  th: "th",
  tha: "th",
  thailand: "th",
  tj: "tj",
  tjk: "tj",
  tajikistan: "tj",
  tl: "tl",
  tls: "tl",
  "timor-leste": "tl",
  tm: "tm",
  tkm: "tm",
  turkmenistan: "tm",
  tn: "tn",
  tun: "tn",
  tunisia: "tn",
  to: "to",
  ton: "to",
  tonga: "to",
  tr: "tr",
  tur: "tr",
  turkey: "tr",
  tt: "tt",
  tto: "tt",
  "trinidad-and-tobago": "tt",
  tv: "tv",
  tuv: "tv",
  tuvalu: "tv",
  tz: "tz",
  tza: "tz",
  tanzania: "tz",
  ua: "ua",
  ukr: "ua",
  ukraine: "ua",
  ug: "ug",
  uga: "ug",
  uganda: "ug",
  us: "us",
  usa: "us",
  "united-states-of-america": "us",
  uy: "uy",
  ury: "uy",
  uruguay: "uy",
  uz: "uz",
  uzb: "uz",
  uzbekistan: "uz",
  vc: "vc",
  vct: "vc",
  "saint-vincent-and-the-grenadines": "vc",
  ve: "ve",
  ven: "ve",
  venezuela: "ve",
  vn: "vn",
  vnm: "vn",
  "viet-nam": "vn",
  vu: "vu",
  vut: "vu",
  vanuatu: "vu",
  ws: "ws",
  wsm: "ws",
  samoa: "ws",
  ye: "ye",
  yem: "ye",
  yemen: "ye",
  za: "za",
  zaf: "za",
  "south-africa": "za",
  zm: "zm",
  zmb: "zm",
  zambia: "zm",
  zw: "zw",
  zwe: "zw",
  zimbabwe: "zw",
  cc: "cc",
  cck: "cc",
  "cocos-islands": "cc",
  "au-cc": "cc",
  cx: "cx",
  cxr: "cx",
  "christmas-island": "cx",
  "au-cx": "cx",
  hm: "hm",
  hmd: "hm",
  "heard-island-and-mcdonald-islands": "hm",
  "au-hm": "hm",
  nf: "nf",
  nfk: "nf",
  "norfolk-island": "nf",
  "au-nf": "nf",
  hk: "hk",
  hkg: "hk",
  "hong-kong": "hk",
  "cn-hk": "hk",
  mo: "mo",
  mac: "mo",
  macao: "mo",
  "cn-mo": "mo",
  tw: "tw",
  twn: "tw",
  taiwan: "tw",
  "cn-tw": "tw",
  fo: "fo",
  fro: "fo",
  "faroe-islands": "fo",
  "dk-fo": "fo",
  gl: "gl",
  grl: "gl",
  greenland: "gl",
  "dk-gl": "gl",
  ax: "ax",
  ala: "ax",
  "aland-islands": "ax",
  "fi-ax": "ax",
  bl: "bl",
  blm: "bl",
  "saint-barthelemy": "bl",
  "fr-bl": "bl",
  gf: "gf",
  guf: "gf",
  "french-guiana": "gf",
  "fr-gf": "gf",
  gp: "gp",
  glp: "gp",
  guadeloupe: "gp",
  "fr-gp": "gp",
  mf: "mf",
  maf: "mf",
  "saint-martin": "mf",
  "fr-mf": "mf",
  mq: "mq",
  mtq: "mq",
  martinique: "mq",
  "fr-mq": "mq",
  nc: "nc",
  ncl: "nc",
  "new-caledonia": "nc",
  "fr-nc": "nc",
  pf: "pf",
  pyf: "pf",
  "french-polynesia": "pf",
  "fr-pf": "pf",
  pm: "pm",
  spm: "pm",
  "saint-pierre-and-miquelon": "pm",
  "fr-pm": "pm",
  re: "re",
  reu: "re",
  reunion: "re",
  "fr-re": "re",
  tf: "tf",
  atf: "tf",
  "french-southern-territories": "tf",
  "fr-tf": "tf",
  wf: "wf",
  wlf: "wf",
  "wallis-and-futuna": "wf",
  "fr-wf": "wf",
  yt: "yt",
  myt: "yt",
  mayotte: "yt",
  "fr-yt": "yt",
  gg: "gg",
  ggy: "gg",
  guernsey: "gg",
  "gb-gg": "gg",
  im: "im",
  imn: "im",
  "isle-of-man": "im",
  "gb-im": "im",
  je: "je",
  jey: "je",
  jersey: "je",
  "gb-je": "je",
  "gb-wls": "wls",
  wls: "wls",
  wales: "wls",
  aw: "aw",
  abw: "aw",
  aruba: "aw",
  "nl-aw": "aw",
  bq: "bq",
  bes: "bq",
  bonaire: "bq",
  "nl-bq": "bq",
  cw: "cw",
  cuw: "cw",
  curaçao: "cw",
  "nl-cw": "cw",
  sx: "sx",
  sxm: "sx",
  "sint-maarten": "sx",
  "nl-sx": "sx",
  bv: "bv",
  bvt: "bv",
  "bouvet-island": "bv",
  "no-bv": "bv",
  sj: "sj",
  sjm: "sj",
  svalbard: "sj",
  "no-sj": "sj",
  ck: "ck",
  cok: "ck",
  "cook-islands": "ck",
  "nz-ck": "ck",
  nu: "nu",
  niu: "nu",
  niue: "nu",
  "nz-nu": "nu",
  tk: "tk",
  tkl: "tk",
  tokelau: "tk",
  "nz-tk": "tk",
  ai: "ai",
  aia: "ai",
  anguilla: "ai",
  "uk-ai": "ai",
  bm: "bm",
  bmu: "bm",
  bermuda: "bm",
  "uk-bm": "bm",
  fk: "fk",
  flk: "fk",
  "falkland-islands": "fk",
  "uk-fk": "fk",
  gi: "gi",
  gib: "gi",
  gibraltar: "gi",
  "uk-gi": "gi",
  gs: "gs",
  sgs: "gs",
  "south-georgia-and-the-south-sandwich-islands": "gs",
  "uk-gs": "gs",
  io: "io",
  iot: "io",
  "british-indian-ocean-territory": "io",
  "uk-io": "io",
  ky: "ky",
  cym: "ky",
  "cayman-islands": "ky",
  "uk-ky": "ky",
  ms: "ms",
  msr: "ms",
  montserrat: "ms",
  "uk-ms": "ms",
  pn: "pn",
  pcn: "pn",
  pitcairn: "pn",
  "uk-pn": "pn",
  sh: "sh",
  shn: "sh",
  "saint-helena": "sh",
  "uk-sh": "sh",
  tc: "tc",
  tca: "tc",
  "turks-and-caicos-islands": "tc",
  "uk-tc": "tc",
  vg: "vg",
  vgb: "vg",
  "british-virgin-islands": "vg",
  "uk-vg": "vg",
  as: "as",
  asm: "as",
  "american-samoa": "as",
  "us-as": "as",
  gu: "gu",
  gum: "gu",
  guam: "gu",
  "us-gu": "gu",
  mp: "mp",
  mnp: "mp",
  "northern-mariana-islands": "mp",
  "us-mp": "mp",
  pr: "pr",
  pri: "pr",
  "puerto-rico": "pr",
  "us-pr": "pr",
  um: "um",
  umi: "um",
  "united-states-minor-outlying-islands": "um",
  "us-um": "um",
  vi: "vi",
  vir: "vi",
  "us-virgin-islands": "vi",
  "us-vi": "vi",

  aq: "aq",
  ata: "aq",
  antarctica: "aq",
  eh: "eh",
  esh: "eh",
  "western-sahara": "eh",
  ps: "ps",
  pse: "ps",
  palestine: "ps",
  va: "va",
  vat: "va",
  "holy-see": "va",
  "vatican-city": "va",
};
