  <div class="fieldRow">
    <div>
      <button mat-mini-fab color="primary" aria-label="Refresh">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <div>
      <h3>Most Active Users</h3>
    </div>
  </div>
  <div style="display:flex;flex-flow:row;">
    <div style="width:75%">
      <countries-map [data]="mapData"></countries-map>
    </div>
    <div style="margin-left:5px;width:20%">
      <strong>Top Countries</strong>
      <div *ngFor="let country of countries" style="display:flex;flex-flow:row;width:100%">
        <div style="width:75%">
          <app-flag [country]="country.Code" size="XS"></app-flag>&nbsp;{{country.Name}}:
        </div>
        <div style="text-align:end">
          {{country.Devices}}
        </div>
      </div>
      <div>
        <button mat-raised-button color="primary" (click)="countrySettings()">Firewall Settings...</button>
      </div>
      <div style="margin-top:5px">
        <button mat-stroked-button routerLink="/users">User Search...</button>
      </div>
    </div>
  </div>
  <table *ngIf="tempSummary | async; else loading" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="UserID">
      <th mat-header-cell *matHeaderCellDef> UID </th>
      <td mat-cell *matCellDef="let element"> {{element.UserID}} </td>
    </ng-container>

    <ng-container matColumnDef="BusinessType">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <div style="margin-top:10px">
          <mat-icon *ngIf="isTemplate(element)" [matBadge]="templateType(element)" matBadgeSize="small" [matBadgeColor]="templateType(element) == 'T' ? 'accent' : 'warn'">{{businessType(element)}}</mat-icon> <mat-icon *ngIf="!isTemplate(element)">{{businessType(element)}}</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="CreateDate">
      <th mat-header-cell *matHeaderCellDef> Created </th>
      <td mat-cell *matCellDef="let element"> {{element.CreatedDate  | date:'short' : '' : translate.currentLang}} </td>
    </ng-container>

    <ng-container matColumnDef="LastLoggedIn">
      <th mat-header-cell *matHeaderCellDef> Last Logged In </th>
      <td mat-cell *matCellDef="let element"> {{element.LastLoggedIn  | date:'medium'}} </td>
    </ng-container>

    <ng-container matColumnDef="UserName">
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let element">
        {{element.UserName}},&nbsp;
        {{element.FirstName}}&nbsp; {{element.LastName}}&nbsp;
        ({{element.EmailAddress}})
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row class="users-element-row" *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></tr>
  </table>
  <ng-template #loading>
    <div class="loading-container">
      <span class="loading-content">
        <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
        <div class="spinner-text"> Loading user analysis...</div>
      </span>
    </div>
    <br /><br />
  </ng-template>
