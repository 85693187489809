import { Component } from '@angular/core';
import { AuditLog, AuthenticationService } from '../../authentication.Service';

@Component({
  selector: 'app-auditlog-activity',
  templateUrl: './auditlog-activity.component.html',
  styleUrl: './auditlog-activity.component.css'
})
export class AuditlogActivityComponent {
  auditLogEntries: AuditLog[] = []
  expanded: boolean[] = [];

  constructor(private authService: AuthenticationService) {
    this.getAuditLog();
  }

  getAuditLog() {
    this.authService.getAuditLogActivity().subscribe(
      result => {
        this.auditLogEntries = result;
        this.expanded = [];
        for (let i = 0; i < this.auditLogEntries.length; i++) {
          this.expanded.push(false);
        }
      }
    )
  }

  getDescription(log: AuditLog): string {
    switch (log.TypeId) {
      case 4:
        return "Login";
      case 5:
        return "Login Failed";
      default:
        return "Unknown";
    }
  }
}
